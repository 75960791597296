@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

/*
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-weight: 700;

  background-color: #F1F2F2;
  border: 2px solid #155799;
  padding: 6px 24px;

  border: solid 4px white;
  border: solid 4px rgb(48, 52, 54);

	overflow: hidden;
*/
.btn {
  font-family: Inconsolata, monospace;
  width: 350px;
  margin: 10px;
  font-size: 2.15em;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  
  border: solid 4px orange;
	padding: 20px 30px;

  outline: 0;
	overflow: auto;
	display: inline-block;
	position: relative;

  background-color: black;
	display: grid;
  transition: all 0.25s ease-in-out;

  align-self: flex-end;
}

/*
  background-color: #155799;
  color: #F1F2F2;

.btn:hover {
  background-color: black;
  border-color: #155799;
  color: #F1F2F2;

  color: #F1F2F2;
}
*/

/*
  :hover when just mouse over.
*/
.btn:hover {
  background-color: #F1F2F2;
  border-color: black;
  color: black;
}

/*
  :active for when users press and hold the button.
*/
.btn:active {
  background-color: transparent;
  border-color: rgb(123, 114, 101);
  color: white;
  box-shadow: none;
}

/*
  :focus means once you already clicked
*/
.btn:focus {
  color: white;
  background-color: #F1F2F2;
  border-color: black;
}

.btn:visited {
  background-color: #155799;
  border-color: #80868C;
  color: black;
}
/*
  color: rgb(255, 251, 245);

  background-color: #155799;
  border-color: #155799;
  color: #F1F2F2;
  outline: 5px auto #155799;

  color: black;
  color: rgb(255, 251, 245) !important;
  
.btn:active:focus {
  color: white !important;
}
*/

/*
.btn:focus {
  color: black;
}

*/
/*
.btn:focus, .btn:active:focus {
  background-color: #155799;
  border-color: #155799;
  color: #F1F2F2;
  outline: 5px auto #155799;
}

.btn:visited {
  background-color: #155799;
  border-color: #155799;
}
*/

/*# sourceMappingURL=buttons_2.css.map */
