/*
@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/css/bootstrap.min.css);

@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
*/ 

/*
  background: linear-gradient(to left, #606c88 , #3f4c6b);
  background: linear-gradient(#414345, #232526);

  width: 100vw;
  height: 100vh;
  overflow: auto;
*/
.faculties-overview {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #360033, #0b8793);
  font-family: 'Oswald', sans-serif;
  flex-direction: column;

  width: 100vw;
  height: 100vh;
}

.category-button {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 20px;
  letter-spacing: 4px;
  position: relative;
  background-color: #16a085;
  border: none;
  color: #fff;
  padding: 20px;
  padding-top:40px;
  padding-bottom:40px;
  width: 250px;
  text-align: center;
  transition-duration: 0.4s;
  overflow: hidden;
  box-shadow: 0 5px 15px #193047;
  border-radius: 4px;
  margin-bottom: 15px;
}

.hall-category-button {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 20px;
  letter-spacing: 4px;
  position: relative;
  background-color: #16a085;
  border: none;
  color: #fff;
  /* 
    padding: 20px;
  */
  padding-top:40px;
  padding-bottom:40px;
  width: 210px;
  text-align: center;
  transition-duration: 0.4s;
  overflow: hidden;
  box-shadow: 0 5px 15px #193047;
  border-radius: 4px;
  margin-bottom: 15px;
}

.faculty-category-container {
  display: grid;
  grid-template-columns: 300px 300px 300px 300px;
}

.affiliate-ministries-category-container {
  display: grid;
  grid-template-columns: 300px 300px 300px;
}

  /* grid-template-columns: 300px 300px 300px 300px 300px; */
  /* 
grid-template-columns: 255px 255px 255px 255px 255px;
  */
.halls-category-container {
  display: grid;
  grid-template-columns: 240px 240px 240px 240px 240px;
}

@media only screen and (max-width:750px) {

  .faculties-overview {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, #360033, #0b8793);
    font-family: 'Oswald', sans-serif;
    flex-direction: column;
    width: 100vw;
    height: auto;
  }
  .category-header {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    color: #FFF;
    margin-top: 90px;
    margin-bottom: 60px;
  }

  .category-description {
    font-family: Inconsolata, monospace;
    font-size: 17px;
    text-align: center;
    color: #FFF;
    width: 95%;
    margin-bottom: 60px;
  }
  
  /*
  .category-button {
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 10px;
    letter-spacing: 4px;
    position: relative;
    background-color: #16a085;
    border: none;
    color: #fff;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 50px;
    text-align: center;
    transition-duration: 0.4s;
    overflow: hidden;
    box-shadow: 0 5px 15px #193047;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  */

  .faculty-category-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;

  }

  .affiliate-ministries-category-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  .halls-category-container {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}

.category-header {
	text-align: center;
	font-size: 50px;
	font-weight: bold;
	font-family: 'Lato', sans-serif;
	color: #FFF;

	margin-top: 60px;
	margin-bottom: 60px;
}

.category-description {
  font-family: Inconsolata, monospace;
	font-size: 24px;
	text-align: center;
	color: #FFF;
    width: 90%;
	margin-bottom: 60px;
}


.hall-category-button:hover, .category-button:hover {
  background: #fff;
  box-shadow: 0px 2px 10px 5px #1abc9c;
  color: #000;
}

.hall-category-button:after, .category-button:after {
  content: "";
  background: #1abc9c;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s
}

.hall-category-button:active:after, .category-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.hall-category-button:focus, .category-button:focus { outline:0; }
