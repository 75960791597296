/*
@import url("https://fonts.googleapis.com/css?family=Montserrat:600&display=swap");
*/

/*
*,
*:before,
*:after {
  box-sizing: border-box;
}

*/

/*
body {
  height: 100vh;
  margin: 0;
  display: grid;
  place-items: center;
}
*/

/*
  margin-left: auto;
  margin-right: auto;

  height: 100vh;
  margin: 0;
  display: grid;
  place-items: center;

  margin-left: -0.5rem;
  margin-right: -0.5rem;

  display:inline-block;
  margin: 100 auto;
*/
.multi-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  background: black;
  overflow: hidden;
}


  /*
    border: 0.125em solid white;

    padding: 0.5em 0.75em;

    border: 0.125em solid rgb(48, 52, 54);

    width: 450px;
    height: 450px;

    width: auto;
    height: auto;
  */
  .multi-button button {
    background: black;
    border: 0.125em solid rgb(123, 114, 101);
    cursor: pointer;
    font: 700 1.5rem/1.25 "Montserrat", sans-serif;
    letter-spacing: 0.125em;
    margin: 1.25rem;
    position: relative;
    text-transform: uppercase;
    color: white;

    width: 350px;
    height: 350px;
  }

    .multi-button button:before, .multi-button button:after {
      content: "";
      position: absolute;
      transition: all 0.125s ease-in-out;
    }

    .multi-button button.cut {
      background: none;
      border-color: transparent;
    }

      .multi-button button.cut:before, .multi-button button.cut:after {
        transition: all 0.175s ease-in-out;
      }

      .multi-button button.cut:before {
        background: black;
        border: 0px dashed black;
        left: -0.125em;
        top: -0.125em;
        right: -0.125em;
        bottom: -0.125em;
        z-index: -1;
      }

      .multi-button button.cut:hover:before, .multi-button button.cut:focus:before {
        background: white;
        border-width: 0.125em;
      }

      .multi-button button.cut:after {
        background: white;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
      }

    .multi-button button.copy:after {
      border: 0.125em dashed black;
      bottom: -0.125em;
      left: -0.125em;
      right: -0.125em;
      top: -0.125em;
      z-index: -1;
    }

    .multi-button button.copy:hover:after, .multi-button button.copy:focus:after {
      bottom: -0.375em;
      left: 0.125em;
      right: -0.375em;
      top: 0.125em;
    }

    .multi-button button.paste:after {
      bottom: 0.125em;
      left: 0.125em;
      right: 0.125em;
      top: 0.125em;
    }

    .multi-button button.paste:hover:after, .multi-button button.paste:focus:after {
      border: 0.125em dashed black;
      bottom: -0.125em;
      left: -0.125em;
      right: -0.125em;
      top: -0.125em;
    }

/*# sourceMappingURL=HomepageDetails.css.map */
@media only screen and (max-width:750px) {
  /*
  #root > div > div {
    padding: 96px 24px 36px 24px;
  }

  .multi-button > a {
    margin: 4px !important;
    width: calc(50% - 8px) !important;
  }
  */
  .multi-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto;
    background: black;
  }

  .multi-button button {
    background: black;
    border: 0.125em solid rgb(123, 114, 101);
    cursor: pointer;
    font: 600 0.75rem/1.25 "Montserrat", sans-serif;
    letter-spacing: 0.125em;
    margin: 0.7rem;
    position: relative;
    text-transform: uppercase;
    color: white;
    width: 115px;
    height: 115px;
  }
}
