/* 
  @import url('https://fonts.googleapis.com/css?family=Bitter|Cabin|Merriweather|Noto+Serif|PT+Sans:400,700|Quicksand|Roboto+Slab|Fira+Sans|Source+Sans+Pro|Playfair+Display:400,400i|Lato|Open+Sans|Josefin+Sans|Bree+Serif|Crete+Round'); 

*/
  @import url('https://fonts.googleapis.com/css?family=Bitter|Cabin|PT+Sans:400,700|Quicksand|Playfair+Display:400,400i|Josefin+Sans|Bree+Serif');

/*Dark Mode*/
/*
* {
    background-color: #222;
    color: #e6e6e6;
    border-color: #e6e6e6;
}

*/

.darkMode{
    background-color: #222;
    color: #e6e6e6;
    border-color: #e6e6e6;
}

:root {
  --font-colour: #021365;
  --tabbar-fontColour: #FFFFFF;
  --font-hover: #394DB1;

  --primary-050: #F0F4F8;
  --primary-100: #D9E2EC;
  --primary-200: #BCCCDC;
  --primary-300: #9FB3C8;
  --primary-400: #829AB1;
  --primary-500: #627D98;
  --primary-600: #486581;
  --primary-700: #334E68;
  --primary-800: #243B53;
  --primary-900: #102A43;  
}

html {
  overflow-y: scroll;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "PT Sans" !important; /* PT Sans */
  font-size: 16px;
  /*background-color: #f5f5f5;*/

  /*Remove the following two lines for light theme*/
  color: #e6e6e6;
  background-color: #222;
  /* line-height: 24px; */
}

#root > div > div {
  padding: 60px 0px 0px 0px;
}
/*
#root > div > div {
  padding: 60px 0px 0px 0px;
}

#root, #root > div {
  height: 100%;
}

#root > div {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
 
THIS HERE IS THE PROBLEM.
#root > div > div {
  padding: 96px 48px 36px 48px;
}
    */

h1 {
  font-size: 52px;
  line-height: 58px;
  margin-top: 36px;
  margin-bottom: 64px;
  padding-bottom: 20px;
  text-align: center;
  position: relative;
}

h1:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 10px;
  /* background-color: rgb(148, 0, 0); */
  background-color: var(--font-hover);
  left: calc(50% - 25px);
  bottom: -8px;
  opacity: 0.8;
  /* z-index: -1; */
}

h2 {
  font-size: 36px;
  margin: 48px 0 24px 0;
}

h1, h2 {
  font-family: "Bitter" !important; /* Bitter, Quicksand */
}

h6 {
  text-align: center;
}

li + li {
  margin-top: 12px;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto; 
}

.slides {
  margin: auto;
  text-align: center;
  vertical-align: middle;
  height: auto;

  color: aliceblue;
}

.align-center {
  text-align: center;
}

.slide-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.container img {
  max-height: 70vh;
  margin-bottom: 18px;
}

.media-object {
  max-width: 300px;
}

.media-logo {
  max-width: 150px;
}

.media-body {
  padding: 10px;
  background-color: aliceblue;
  width: 300px;
}

.padded-container {
  padding: 100px
}

.centered {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clickable {
  position:absolute; 
  width:100%;
  height:100%;
  top:0;
  left: 0;
  z-index: 1;
}

.image-box {
  max-width: 250px;
}

.block-quote {
  font-style: italic;
  font-size: 24px;
  margin-left: 16px;
  padding-left: 16px;
  font-family: "Playfair Display";
  position: relative;
  display: block;
  /* border-left: 8px solid #eee; */
}

.block-quote:before {
  /* content: "“";
  font-style: normal;
  font-size: 84px;
  position: absolute;
  left: -30px;
  top: -45px;
  opacity: 0.15; */
}

.block-quote:after {
  /* content: "”";
  font-style: normal;
  font-size: 84px;
  position: absolute;
  right: -36px;
  bottom: -58px;
  opacity: 0.15; */
}

.row {
  align-items: center;
  justify-content: center;
}

/* Navbar */
/*
  width: 100%;
*/
.navbar {
  z-index: 100;
  position: absolute;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
}

.bg-light {
  background: rgba(0,0,0,0) !important;
}

.navbar-brand {
  color: white;
  display: flex;
  align-items: center;
  margin: 0;
}

/*
  color: var(--font-colour);

*/
.navbar-brand .a{
  color: white;
}

.navbar-brand > img {
  margin-right: 10px;
}

.navbar-brand:hover {
  color: var(--font-hover);
}

/*

  color: var(--font-colour);
*/
/*
  The magic to making Tabbar font color white
*/
.nav-link {
  color: white !important; 
}

.nav-item {
  padding: 0px 24px;
  margin: 0;
}

.navbar-collapse {
  z-index: 1000;
}


.nav-item a:hover {
  text-decoration: none !important;
}

.nav-link:hover, .active:hover {
  color: var(--font-hover);
}

.dropdown-item {
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: var(--font-hover);
  text-decoration: none;
  color: white;
}

.dropdown-toggle {
  white-space: nowrap;
}

/* HOMEPAGE */
#homepage {
  position: absolute;
  top: 72px;
  left: 0;
  padding: 0 !important;
  width: 100%;
  /* margin-top: 36px; */
}

/* NOT IN USE */
#homepage-splash {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: calc(100vh - 72px); */
  padding: 64px 0;
  background: url("./img/vcf-foc-18-finale-blur.jpg") no-repeat center center;
  background-size: cover;
  color: white;
}

/* Just for testing purposes. 
  background-image: url("./img/salt_and_light.jpeg");
  height: 100%;
  height: 500px;

  background-image: url("./img/snl_wide_blur.png");

  border: 1px solid #DDDDDD;
*/
#homepage-splash-b {
  /* min-height: calc(100vh - 72px); */
  /* Vertical padding from top */
  padding: 25% 0%;

  /* To obtain full page image. Center and scale the image nicely */
  background-image: url("./img/nus_vcf_background_2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   
  width: 100vw;
  height: 100vh;

  
  /*
    TCB 0208
  */
  align-items: center;
  display: flex;
  justify-content: center;
}

/*
    For Welcome Section on Homepage.
  height: 100%;
  padding: 25% 0%;
  height: 550px;
*/
#welcome-splash-a {
  /* min-height: calc(100vh - 72px); */
  /* Vertical padding from top */
  /* To obtain full page image. Center and scale the image nicely */
  background-image: url("./img/three_obj_3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*
  bottom: auto;
  right: 20px;

  width: 50%;
  max-width: 425px;
  position: absolute; 

  margin: auto;
  bottom: 0px; 
  //color: rgb(123, 114, 101);
  color: #F9DE6A4;

  border: 1px dotted #DDDDDD;
*/
#test-vision {
  background-color: transparent;
  color: orange;
  opacity: 0.7;
   
  font-family: Inconsolata;
  color: #f3a83f;
  text-align: center;
  width: 38%;
  font-size: 25px;
  
  margin-bottom: 25px;
}

#homepage-vision-a {
  font-family: "Josefin Sans";
  font-size: 20px;
  color: white;

  opacity: 0.7;
  /* New adds */
  border: 1px dotted #DDDDDD;
  vertical-align: middle;

  text-align: center;
margin: auto;
position: relative;
}

/*
  position: relative;
  clear: left;
  z-index: 0; 
  margin: 0 auto;

*/
#welcome-splash-b {
  text-align: center; /* Add This*/ 
  width: 900px;
  height: 915px;
  font-size: 55px;

  position: relative;
  clear: left;
  z-index: 0; 
  margin: 0 auto;
}

/*

  background-image: url("./img/nus_vcf_background_1.jpeg");

  background-image: url("./img/text_background_2.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
  text-align: center;
  color: white;

  width: 900px;
  height: 900px;
*/
#category-splash-a {
  /* To obtain full page image. Center and scale the image nicely */

  border: 1px solid #DDDDDD;
  
  /* place-items: center; */
}

#category-splash-b {
  text-align: center; /* Add This*/ 
  margin-left: auto;
  margin-right: auto;
}

#homepage-splash-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* padding: 48px 0 54px 0; */
  /* background-color: rgba(0,0,0,0.7); */
  /* box-shadow: 0 0 5px 1px rgba(0,0,0,0.4); */
}

#homepage-title, .navbar-brand {
  font-family: "Bree Serif" !important; /* Merriweather, Quicksand, Raleway, Roboto Slab, Josefin Sans, Crete Round, Bree Serif */
  /* text-transform: uppercase; */
  /* letter-spacing: 4px; */
}

#homepage-title {
  /* margin: 0px auto 24px auto; */
  margin-top: 24px;
  margin-bottom: 32px;
  /* font-weight: bold; */
  font-size: 64px;
  line-height: 64px;
  /* line-height: 84px; */
  color: white;
  /* text-shadow: 0 0 40px black; */
}

#homepage-vision {
  font-family: "Josefin Sans";
  /* font-style: italic; */
  font-size: 20px;
  max-width: 425px;
  /* line-height: 28px; */
  color: white;
  /* text-shadow: 0 0 40px black; */
  margin: 0 24px;
  opacity: 0.7;
  margin-bottom: 18px;
}

/*
  margin: 0 24px;
  max-width: 425px;
  position: absolute; 

  margin: auto;
  bottom: 0px; 
  width: 40%;
  align-items: center;

  display: flex;
  justify-content: center;

*/

#homepage-cta {
  color: white;
  border: 2px solid white;
  text-decoration: none;
  font-size: 14px;
  /* font-weight: bold; */
  font-family: "Josefin Sans";
  text-transform: uppercase;
  letter-spacing: 6px;
  padding: 12px 32px;
  margin-top: 32px;
}

#homepage-cta:hover {
  background: rgba(255,255,255,0.1);
}

#homepage-more-info {
  padding: 72px 0px;
  width: 100%;
  max-width: 1000px;
  margin: 0px auto;
  text-align: left;
}

/* BELIEFS */
.distinctive {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.distinctive img {
  height: 80px;
  margin-top: 12px;
}

.distinctive h3 {
  margin: 12px 0 12px 0;
}

.distinctive p {
  /* text-align: justify; */
}


/* MINISTRY THUMBNAILS */
.ministry-thumbnail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 36px 0; */
  position: relative;
  margin: 12px 0;
}

.ministry-thumbnail img {
  /* height: 160px; */
  /* margin-bottom: 8px; */
  max-width:100%;
  /* max-height: 400px; */
}

.ministry-thumbnail div {
  /* text-decoration: underline; */
  /* font-size: 16px; */
  font-weight: bold;
  letter-spacing: 3px;
  /* color: var(--font-hover); */
  position: absolute;
  color: white;
  background-color: rgba(0,0,0,0.1);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;
  
}

.ministry-thumbnail div > span {
  text-shadow: 0 0 10px black, 0 0 15px black;
  font-family: "Quicksand";
  text-transform: uppercase;
}

.ministry-thumbnail:hover {
  background-color: rgba(255,255,255,0.5);
  text-decoration: none;
}

.ministry-thumbnail div:hover {
  opacity: 0.7;
}

/*
    padding: 96px 24px 36px 24px;

*/
@media only screen and (max-width:750px) {
  #root > div > div {
    padding: 60px 0px 0px 0px;
  }

  .nav-item {
    background-color: black;
    border: 1px solid rgba(0,0,0,.15);
    /* border-radius: .25rem; */
    margin-top: -1px;
  }

  .nav-link {
    color: white !important; 
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
  }

  #homepage-more-info {
    text-align: left;
  }

  #test-vision {
    font-size: 22px;
    width: 90%;
  }



  img {
    max-width: 100%;
  }

  .distinctive {
    width: 100%;
  }

  .block-quote {
    font-size: 20px;
  }

  .ministry-cells > a {
    margin: 4px !important;
    width: calc(50% - 8px) !important;
  }

  #homepage-splash-b {
    /* min-height: calc(100vh - 72px); */
    /* Vertical padding from top */
    /* To obtain full page image. Center and scale the image nicely */
    background-image: url("./img/vcf_text_mobile_view.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;

    align-items: center;
    display: flex;
    justify-content: center;
  }

  #welcome-splash-a {
    /* To obtain full page image. Center and scale the image nicely */
    background-image: url("./img/snl_mobile_view.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


/* Welcome Tea stuff */

#ministry-header {
  margin-top: 0;
}

.ministry p {
  /* margin-top: 24px; */
  margin-bottom: 24px;
  font-size: 20px;
  max-width: 820px;
}

#homepage hr {
  border-top: 2px solid rgba(173, 173, 173, 0.342);
  width: 20vw;
  margin: 36px 0;
}

.ministry {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ministry-cells {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 12px;
}

.ministry-cells > a {
  padding: 12px;
  margin: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  transition: all 0.3s;
}

.ministry-cells > a:hover {
  text-decoration: none;
  background-color: rgba(0,0,0,0.03);
}

.ministry-info {
  flex: 1;
  margin-bottom: 22px;
}

.ministry-info table {
  border: 0;
}

.ministry-info td {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.ministry-readmore {
  /* color: white; */
  /* border: 2px solid white; */
  text-decoration: none;
  font-size: 11px;
  font-family: "Josefin Sans";
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
  text-align: right;
  display: block;
  margin-top: 4px;
}

.ministry .affiliate {
  width: calc(33% - 24px);
}

.ministry .fac {
  width: calc(25% - 24px);
}

.ministry .hostel {
  width: calc(20% - 24px);
}

.ministry h3 {
  text-transform: uppercase;
  letter-spacing: 6px;
  font-weight: bold;
  font-size: 20px;
  margin: 48px 0 36px 0;
  position: relative;
  color: var(--primary-600);
}

.ministry h3::after {
  content: "";
  position: absolute;
  width: 28px;
  height: 3px;
  /* background-color: white; */
  background-color: var(--primary-200);
  left: calc(50% - 14px);
  bottom: -20px;
}

.ministry h4 {
  margin: 12px 0 0px 0;
  font-size: 24px;
  position: relative;
  font-family: 'Bitter';
  /* text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 3px; */
}

.ministry .fac h4 {
  color: rgb(51, 121, 51);
}

.ministry .hostel h4 {
  color: rgb(138, 57, 127);
}

.ministry .affiliate h4 {
  color: rgb(66, 64, 172);
}

/* .ministry h4::after {
  content: "";
  position: absolute;
  width: 140px;
  height: 1px;
  background-color: #ddd;
  left: calc(50% - 70px);
  bottom: -8px;
} */

.ministry .title {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 4px;
  font-size: 13px;
  width: 90px;
  text-align: left;
  opacity: 0.7;
}

.box-btn {
  padding: 10px 24px;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 18px;

  color: var(--primary-100);
  background-color: var(--primary-700);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  transition: all 0.3s;
}

.box-btn:hover {
  color: white;
  background-color: var(--primary-600);
  text-decoration: none;
}

#latest-blog-post-row {
  display: flex;
  flex-direction: row;
  padding: 0 12px;
  margin-bottom: 24px;
  max-width: 1200px;
}

#latest-blog-post-row h3 a {
  color: rgba(255,255,255,0.9);
  font-size: 20px;
}

#latest-blog-post-row .card {
  margin: 8px;
  background-color: rgba(0,0,0,0.5);
  color: white;
  border-radius: 0;
}

#latest-blog-post-row a {
  color: rgba(255,255,255,0.8);
}

.fill-window {
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
}